.QRGenerator{
	width: 80%;
  margin: 0;
  padding: 0 10%;
  display: flex;
  flex-direction: column;
  color: #e8f3ffff;

  font-size: 1.3em;
}

.QRInputs{
	margin: auto;
}
.QRContainer{
	max-width: 100%;
	margin: auto;
}

.qrContent{
	display:grid;

	align-content:center;
	grid-template-columns: 1fr;
	grid-gap:.5em;
}


@media only screen and (min-width: 720px) {
	.qrContent{
		grid-template-columns: 1fr 1fr;
	}
}

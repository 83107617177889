.Waitlist{
	height: 100%;
	min-height: 80vh;
	display: flex;
	flex-direction: column;
}

.Waitlist form{
  width:90%;
}
.Waitlist input,
.Waitlist .Input,
.Waitlist .Toggle{
  width:100%;
}

@media (orientation: landscape) {
  .Waitlist form{
    width:40%;
  }
}

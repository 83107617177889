.InstagramComment{
  color:black;
}


.InstagramComment .comment{
  display:grid;
  font-family: sans-serif;
  grid-template: 'profile comment like'
  'profile timestamp like';
  grid-gap:1em;
  background: white;
  padding:1em;
}
.InstagramComment .profile{
  grid-area: profile;
  height:2.5em;
  aspect-ratio:1/1;
  border-radius:50%;
  background:grey;
}
.InstagramComment .text{
  grid-area: comment;
  text-align: start;
}
.InstagramComment .username{
  font-weight:bold;
}
.InstagramComment .verified{
  display: inline;
  height: 1em;
  padding: 0 .25em;
  align-self: end;
}
.InstagramComment .like{
  grid-area: like;
  height: 1.25em;
  margin: auto;
  margin-top: min(.75em);
}
.InstagramComment .timestamp{
  grid-area: timestamp;
  text-align: start;
}
.InstagramComment .timestamp > span{
  margin-right:1em;
  opacity:.5;
}

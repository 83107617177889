/* ----what each shares------ */
a:has(.glide){

}
.glide{
  position: relative;
  background: none;
  width: auto;
  display: grid;
  text-align: left;
  font-size: .75rem;
  font-weight: bold;
  grid-template:  "title img" auto
                  "text text" auto / 1fr auto;
  --content-margin-top: 1em;
  --content-margin-right: 1em;
  --other-margin: 1em;

  padding: 0 0 var(--other-margin) var(--other-margin);
  color: var(--tertiary);
  height: calc(100% - var(--other-margin));
  z-index: 0;
  max-width: calc(100% - 2em);
}
a:has(.glide){

}
.glide::before{
  content: " ";
  background: radial-gradient(ellipse at top right, #fff2, #0000) var(--primary);
  border-radius: 1em;
  filter: drop-shadow(2px 2px 10px #11111199);

  position: absolute;
  inset: var(--content-margin-top) var(--content-margin-right) 0 0;
  z-index: -1;
  transition: filter .25s;
}

.glide:hover::before{
  filter: drop-shadow(2px 2px 8px var(--secondary));
}
.glide img{
  grid-area: img;
  align-self: end;
  max-height: 8em;
  margin: 0 0 auto auto;
  filter: drop-shadow(var(--secondary) 0 0 10px);
}
.glide .title{
  grid-area: title;
  font-size: 3em;
  margin: auto auto 0 0;
  white-space: nowrap;
}
.HorizontalList .glide .title{
  overflow: visible;
}
.VerticalList .glide .title{
  overflow: hidden;
}
.glide .text{
  grid-area: text;
  font-size: 1.5em;
  width: calc(100% - var(--content-margin-right));

}
.glide{
  min-height: calc(100% - 2.75em);
}
.glide.hasImage{

}

.glide.Primary::before{
  background: radial-gradient(ellipse at top right, #fff4, #fff0) var(--quarnary);
}
.glide.Primary .title,
.glide.Primary .text{
  color: var(--primary);
}


/* ------if each element is not in card----- */
.glide:not(.hasImage){

}
.glide:not(.hasImage) > .title{

}
.glide:not(.hasImage) > .description{

}


.glide:not(.hasImage) > .title{
  margin-right:var(--content-margin-right);
}

/* --------all-------- */
.glide.hasImage.hasTitle.hasText{

}
.glide.hasImage.hasTitle.hasText  img{

}
.glide.hasImage.hasTitle.hasText  .title{
}
/* -------only image and text----------- */

.glide.hasImage.hasText:not(.hasTitle){

}
/* ------only title and text----- */
.glide.hasTitle.hasText:not(.hasImage){

}
.glide.hasTitle.hasText:not(.hasImage) .title{
  padding-top: calc(var(--other-margin) / 2);
}
/* ------only title----------- */
.glide.hasTitle:not(.hasImage):not(.hasText) {

}
.glide.hasTitle:not(.hasImage):not(.hasText) .title{
  padding-top: calc(var(--other-margin)/2);
}
/* ------only text----------- */
.glide.hasText:not(.hasImage.hasTitle){

}
.glide.hasText:not(.hasImage):not(.hasTitle) .text{
  padding-top: var(--other-margin);
}
/* ---------------------------- */

@media (orientation: landscape){
  .glide{
    font-size: 1rem;
  }
}

.ColorPicker{


}

.inputWrapper{
  display: inline;
  position: relative;
  --color: #0000ff;
  --selectedHue: 0;
  --selectedSaturation: 100;
  --selectedLightness: 100;
}

.ColorDrawer{
  height: 0;
  overflow: hidden;
  position: absolute;
  transition: height .3s;
  top:100%;
  left:0;
  background: var(--primary);
  width: 100%;
  border-radius: .5em;
  display: grid;
  grid-template-columns: 80% 20%;
  align-items: center;
}
.ColorPicker input:focus ~ .ColorDrawer,
.ColorDrawer:hover{
  z-index: 2;
  height: 8em;
  width: calc(100% - 1em);
  padding: .5em;
}
.ColorWheel{
  border-radius: 50%;
  aspect-ratio: 1/1;
  width: 7em;
  margin: auto;
  position: relative;
  background: radial-gradient(#ffffffff 0%, #ffffffcc 25%, #ffffff00 70%),
                conic-gradient(from 90deg,
                                  hsl(0, 100%, 50%) 0%,
                                  hsl(36, 100%, 50%) 10%,
                                  hsl(72, 100%, 50%) 25%,
                                  hsl(108, 100%, 50%) 35%,
                                  hsl(144, 100%, 50%) 40%,
                                  hsl(180, 100%, 50%) 50%,
                                  hsl(216, 100%, 50%) 60%,
                                  hsl(252, 100%, 50%) 70%,
                                  hsl(288, 100%, 50%) 80%,
                                  hsl(324, 100%, 50%) 90%,
                                  hsl(359, 100%, 50%) 99%
                                );

}
.ColorShadeBar{
  border-radius: .5em;
  aspect-ratio: 1/7;
  width: 1em;
  margin: auto;
  position: relative;
  background: linear-gradient(hsl(var(--selectedHue), calc(var(--selectedSaturation)*1%), 100%) 0%,
                                hsl(var(--selectedHue), calc(var(--selectedSaturation)*1%), 50%) 50%,
                                hsl(var(--selectedHue), calc(var(--selectedSaturation)*1%), 0%) 100%), var(--color);
}
.ColorPreview{
  aspect-ratio: 1/1;
  max-height: 90%;
  height: 1em;
  position: absolute;
  top:.25em;
  right:.25em;
}

.Handle{
  min-width: 0.35em;
  min-height: 0.35em;
  border-radius: 0.35em;
  position: absolute;
  border: 2px solid black;
  background: var(--color);
  transform: translate(-50%, 50%);
  pointer-events: none;
}
.ColorWheel .Handle{
  left: var(--wheelX);
  top: var(--wheelY);
  transform: translate(-50%, -50%);
  /* left: 50%;
  bottom: 50%;
  transform-origin: 50% 50%;
  transform:  rotate(calc((var(--selectedHue) - 90) * 1deg)) translateY(calc(var(--selectedSaturation)/100 * 3.5em)); */
}
.ColorShadeBar .Handle{
  width: 110%;
  left: 50%;
  bottom: calc(var(--selectedLightness)*1%);
}

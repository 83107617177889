.Header{

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 1em);
  padding: .5em;
  position: relative;

}
.HeaderStart{
  height: 2.5em;
}
.HeaderStart  a, .HeaderStart img{
  display: block;
  height: 100%;
}
.Link{

}
.Link > a{
  white-space: nowrap;
}

.DesktopMenu{
  display: none;
  color: var(--tertiary);
  font-size: 1.5em;
}
.DesktopMenu > *{
  margin: 0 .5em;
}
.HeaderEnd{
  height: 2.5em;
}
.HeaderEnd div, .HeaderEnd img{
  display: block;
  height: 100%;
}
.HeaderEnd > .CTA{
  display: none;
}



.MobileMenu{
  font-size: 1.5rem;
  position: absolute;
  left: 100vw;
  width: 90vw;
  top: 5vh;
  height: 90vh;
  transition: left .25s;
  padding-top: 1em;

  border-radius: 1em;
  background: #00000044;
  backdrop-filter: blur(10px);

  display: flex;
  flex-direction: column;
  z-index: 2;
}
.MobileMenu > .Link{
  padding: 1em 0;
  margin: 0 1em;
  text-align: left;
  border-bottom: 1px solid var(--tertiary);
}
.MobileMenu .Drawer{
  height: auto;

  max-height: 0;
  overflow: hidden;
  transition: max-height .5s ease-out;
}
.MobileMenu .Drawer.Open{
  max-height: 50vh;
  transition: max-height .5s ease-in;
}
.MobileMenu .Drawer .Link{
  margin: .5em 2em;
  text-align: left;
}
.MobileMenu.open{
  left: 5vw;
}
.MobileMenu > .CTA{
  width: 90%;
  margin: auto auto 1em auto;
}

.Header a{
  color: inherit;
  text-decoration: none;
}

.Hamburger{

}
.CTA{
  background: var(--quarnary);
  color: var(--tertiary);
  border-radius: .5em;
  font-weight: bold;
  font-size: 2rem;
  padding: .2rem .75rem;

  cursor: pointer;
  transition: filter .5s;
}
.CTA:hover{
  filter: brightness(.9);
}

.Link:hover,
.Link:hover{
  cursor: pointer;
  text-shadow: 0px 0px 5px var(--tertiary);
}

@media (max-width: 767px){
  .Template:has(.MobileMenu.open){
    height: 100dvh;
    overflow: hidden;
  }
}
@media (min-width: 768px) {
  .Header{

  }
  .HeaderStart{

  }
  .NestedMenu{
    position:relative;
    overflow: visible;
  }
  .Drawer{
    display: none;
    position: absolute;
    top:100%;
    left:-.5em;
    padding:.25em .5em;
    background: #000b;
    z-index: 2;
  }
  .Link{
    text-align: left;
  }
  .Link:hover + .Drawer,
  .Drawer:hover{
    display:block;
  }
  .DesktopMenu{
    display: flex;
  }
  .MobileMenu, .MobileMenu.open{
    left: 100vw;
  }

  .HeaderEnd > .Hamburger{
    display: none;
  }
  .HeaderEnd > .CTA{
    display: block;
    font-size: 1.5rem;
  }
}

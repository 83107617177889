.Toggle{
  text-align: left;
}
.toggleInput{
  height: 1em;
  width: 2.2em;
  border-radius: 1.5em;
  background: lightgrey;
  display: inline-block;
  position: relative;

  font-size: .75em;
  padding: .5em .4em;
  margin: 1.5em 0 0 0;
  font-weight: 500;
  transition-duration: .3s;
}
.toggleButton{
  height: 1.5rem;
  aspect-ratio: 1/1;
  margin: -0.2rem 0 0 0;
  border-radius: 50%;
  background: grey;
  transition: transform .3s, background-color .3s;
}
.toggleInput > input{
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
  display: block;
  opacity: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  cursor: pointer;
}


.toggleInput > input:checked ~ .toggleButton {
  background: #0783e4ff;
  transform: translateX(0.9em);
}

.Hero{

  width: 80%;
  height: calc(100vh - 3em);
  margin: auto 0 0 0;
  padding: 0 10%;
  display: flex;
  flex-direction: column;

  font-size: 1.3em;

  overflow: hidden;
  position: relative;
  z-index: 0;
}

.Hero .main{
  margin: 7em auto auto auto;
  filter: drop-shadow(2px 2px 8px #222);
}

.login{
  position: absolute;
  top:1em;
  right:1em;
  font-size: 1rem;
}
.login > a{
  color: inherit;
  text-decoration: none;
}

.login, .callToAction{
  width: fit-content;
  block-size: fit-content;
  background: #0570c0ff;
  border-radius: .5em;
  margin: 0 auto;
  padding: .4em .7em;
  font-weight:bolder;
}

.callToAction{
  cursor: pointer;
  transition: filter .25s linear;
}
.callToAction:hover{
  filter: drop-shadow(2px 2px 8px var(--secondary));
}

.star{
  --xCoor: 40;
  --yCoor: 40;
  --delay: 0;

  position: absolute;
  top:calc(var(--xCoor) * 100%);
  left:calc(var(--yCoor) * 100%);

  border-radius: 50%;

  width: 4px;
  height: 15px;
  background: #ffffffaa;

  animation: shimmer 4s linear calc(var(--delay) * 4s) infinite;

  opacity: 0;
}
.star::before{
  display: block;
  content: " ";
  height: 4px;
  width: 15px;


  border-radius: 50%;

  background: #ffffffaa;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes shimmer {
  0% {transform: rotate(0deg) scale(.8); opacity: 0;}
  25% {opacity: 0;}
  50% {transform: rotate(180deg) scale(.5); opacity: 1;}
  75% {opacity: 0;}
  100% {transform: rotate(360deg) scale(.8); opacity: 0;}
}

.Footer{
  width: 100%;
  align-self: flex-end;
  align-items: center;

  color: var(--tertiary);
  opacity: .5;
  font-size: 1.5em;
  font-weight: bolder;
}

.Footer > *{
  color:inherit;
  text-decoration: none;
}

.Footer svg{
  height: 1em;
  display: inline-block;
  padding: 0 0.25em;
  fill: var(--tertiary);
}

.Login{
	height: 100%;
	min-height: 80vh;
	display: flex;
	flex-direction: column;
}

.Login:first-child{
	margin: auto;
	width: fit-content;
	height: fit-content;
}

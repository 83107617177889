
.Layout1{
  width: 80%;
  margin: 0 auto;
}

.Layout1>
.profile{
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1.5em;
  padding: 1em 0;
}
.Layout1>
.profile > *:first-child{
  margin: auto;
}
.Layout1
.bio{
  margin: auto 0;
  text-align: left;
  display: block;
}
.Layout1
.name{
  font-size: 1.5em;
  font-weight: bold;
}
.Layout1
.bio span{
  display: block;
}

.Layout1>
.social-section{
  margin: .5em auto;
  height: 4.5em;
  width: 80%;
  justify-content: space-around;
}

.Layout1>
.link-section{
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1em;
  margin: .5em 0 0 0;
  justify-items: center;
}
.Layout1>
.link-section > *{
  grid-column: span 12;
}


@media only screen and (min-width: 480px) {

}
@media only screen and (min-width: 768px) and (max-width:1079px) {
  .Layout1>
  .link-section{

  }
  .Layout1>
  .link-section > *{
    grid-column: span 6;
  }
  .Layout1>
  .link-section > *:first-of-type{
    grid-column: span 12;
  }
  .Layout1>
  .link-section > *:nth-child(even):nth-last-child(1){
    grid-column: 4 / span 6;
  }
}
@media only screen and (min-width: 1080px) {
  .Layout1>
  .link-section{

  }
  .Layout1>
  .link-section > *{
    grid-column: span 4;
  }
  .Layout1>
  .link-section > *:first-of-type{
    grid-column: span 12;
  }
  .Layout1>
  .link-section > *:nth-child(3n+2):nth-last-child(2){
    grid-column: 3 / span 4;
  }
  .Layout1>
  .link-section > *:nth-child(3n+3):nth-last-child(1){
    grid-column: 7 / span 4;
  }
  .Layout1>
  .link-section > *:nth-child(3n+2):nth-last-child(1){
    grid-column: 5 / span 4;
  }
}

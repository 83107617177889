.LayoutWrapper{
  overflow-y: auto;
  scroll-behavior: smooth;
}

.LayoutWrapper.grid{
  --grid-color: color-mix(in srgb, var(--tertiary), transparent 80%);
  background: repeating-linear-gradient(#0000, #0000 49px, var(--grid-color) 49px, var(--grid-color) 50px, #0000 50px, #0000 99px),
              repeating-linear-gradient(90deg, #0000, #0000 99px, var(--grid-color) 99px, var(--grid-color) 100px);
}


.content:has(.Layout2){
  display: flex;
  align-items: center;
}
.Layout2{
  background: var(--primary) linear-gradient(60deg, var(--secondary) 0%, var(--primary) 100%);
  border-radius: 1em;
  box-shadow: 10px 10px 5px 5px #00000077;
  overflow: hidden;
  margin: 1em auto auto auto;
  width:min(50vh, 95%);
  padding: 0 0 1em 0;
}

.Layout2
.profile{
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1.5em;
  padding: 1em 0;
}
.Layout2
.profile > *:first-child{
  margin: auto;
}
.Layout2
.bio{
  margin: 1em 0 0 1em;
  text-align: left;
  padding: 0 1em;
}
.Layout2
.name{
  font-size: 1.75em;
  font-weight: bold;
}
.Layout2
.description{
  font-size: 1.25em;
  margin: 0 0 0 .5em;
}
.Layout2
.bio > span{
  display: block;
}

.Layout2
.social-section{
  margin: .5em auto;
  height: 4.5em;
  width: 80%;
}

.Layout2
.link-section{
  margin: 0 auto;
  display: block;
  margin: .5em 0 0 0
}
.Layout2
.link-section > *{
  margin-top:.5em;
}


.Layout2 .Contact{
  border-radius: 1.1em;
  cursor:pointer;
  text-align:right;
  margin: .2rem 1em 0 auto;
  padding: 0rem .8rem .2rem .8rem;
  font-weight:bold;
  width: fit-content;
  background:var(--primary);
}

@media only screen and (min-width: 480px) {

}
@media only screen and (min-width: 768px) and (max-width:1079px) {
  .Layout2
  .link-section{

  }
  .Layout2
  .link-section > *{
    grid-column: span 6;
  }
  .Layout2
  .link-section > *:first-of-type{
    grid-column: span 12;
  }
  .Layout2
  .link-section > *:nth-child(even):nth-last-child(1){
    grid-column: 4 / span 6;
  }
}
@media only screen and (min-width: 1080px) {
  .Layout2
  .link-section{

  }
  .Layout2
  .link-section > *{
    grid-column: span 4;
  }
  .Layout2
  .link-section > *:first-of-type{
    grid-column: span 12;
  }
  .Layout2
  .link-section > *:nth-child(3n+2):nth-last-child(2){
    grid-column: 3 / span 4;
  }
  .Layout2
  .link-section > *:nth-child(3n+3):nth-last-child(1){
    grid-column: 7 / span 4;
  }
  .Layout2
  .link-section > *:nth-child(3n+2):nth-last-child(1){
    grid-column: 5 / span 4;
  }
}


.AbilityCard{
  width: 90%;
  margin: auto;
  color: var(--tertiary);
  font-weight:bolder;

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
a:has(.AbilityCard){
  text-decoration: none;
  display: grid;
  align-items: end;
}
.AbilityCard:hover{
  cursor: pointer;
}
.AbilityCard img{
  height: 2em;
}
.AbilityCard .title{
  display: none;
}
.AbilityCard .text{
 margin-left:1em;
 text-align: left;
}
.AbilityCard.hasImage{

}
.AbilityCard:not(.hasImage)>img{
  content: url('../../public/swirl.png');
}

.shimmer{
  overflow: hidden;
  position: relative;
}

.shimmer::after{
  content: ' ';
  display: block;
  position: absolute;
  inset: 0;
  pointer-events: none;
  background-image: linear-gradient(-60deg, #00000000 55%, #ffffffff 60%, #00000000 65%);
  background-size: 500% 500%;
  animation: shimmerAnimation 4s linear 0s infinite alternate;
}

@keyframes shimmerAnimation {
  0% {background-position: top left;}
  20% {background-position: top left;}
  40% {background-position: bottom right;}
  100% {background-position: bottom right;}
}
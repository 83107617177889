.StackedLine{
  position: relative;
}

.StackedLine > .key{
  padding: .5em;
  background: #00000099;
  position: absolute;
  left: 10%;
  top: 0%;
  text-justify: left;
}
.StackedLine > .key > div{
  width: fit-content;
}

.StackedLine .keyColor{
  display: inline-block;
  height:1em;
  aspect-ratio: 1/1;
  content: " ";
}

.line{
  --line-color: rgba(255, 0, 0, 1);
  fill:none;
  strokeWidth:3;
  stroke: var(--line-color);
  /* filter: drop-shadow(0px 10px 10px var(--line-color))
            drop-shadow(0px 20px 20px var(--line-color)); */
}

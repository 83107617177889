.Button{
  --color:white;
}

.Button > button{
	position: relative;
	box-shadow: 0px 5px 0px 0px var(--color);
	transform: translate(0px, -5px);
	color: var(--color);
}

.Button > button:hover{
	cursor: pointer;
}


.Button > button:active {
	transform: translate(0px, 5px);
	box-shadow: 0px 0px 0px 0px var(--color);
	transform: translateY(0);

}
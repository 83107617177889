/* styling that form elements all share */

.Form{

}



.Input,
.Number,
.Select,
.MultiSelect,
.ColorPicker,
.Button,
.Toggle,
.File{
  position: relative;
  width: fit-content;
  font-size: 1.5rem;
  max-width: 100%;
}
.Input > input,
.Number > input,
.Select > select,
.MultiSelect > select,
.ColorPicker input,
.Button > button,
.File > input{
  --color: white;

  font-size: .75em;
  border: 2px solid var(--color);
  background-color: transparent;
  border-radius: .5em;
  padding: .5em .4em;
  margin: 1.5em 0 0 0;
  color: grey;
  font-weight: 500;
  outline: none;
  caret-color: rgb(155, 78, 255);
  transition-duration: .3s;


  max-width: 100%;
  box-sizing: border-box;
}
.Input > input:focus,
.Input > input:not(:placeholder-shown),
.Number > input:focus,
.Number > input:not(:placeholder-shown),
.Select > select:focus,
.Select > select:valid,
.MultiSelect > select:focus,
.MultiSelect > select:valid,
.ColorPicker input:focus,
.ColorPicker input:not(:placeholder-shown) {
  color:black;
  background-color: #ddd;
  transition-duration: .3s;
}
.Input > label,
.Number > label,
.Select > label,
.MultiSelect > label,
.ColorPicker > label,
.Toggle > label{
  font-size: .75em;
  overflow: hidden;
  text-align: left;

  position: absolute;
  top:.2em;
  left:0em;

  opacity: 0;
  transition: opacity .25s, left .25s;
  pointer-events: none;
}
.Input > input:focus + label,
.Input > input:not(:placeholder-shown) + label,
.Number > input:focus + label,
.Number > input:not(:placeholder-shown) + label,
.Select > select:focus + label,
.Select > select:valid + label,
.MultiSelect > select:focus + label,
.MultiSelect > select:valid + label,
.ColorPicker > .inputWrapper:has(input:focus)  + label,
.ColorPicker > .inputWrapper:has(input:not(:placeholder-shown))  + label,
.Toggle > label  {
  opacity: 1;
  left: .25em;
}
.Input > input::placeholder,
.Number > input::placeholder,
.ColorPicker > input::placeholder {
  opacity:1;
  transition: opacity .25s;
}
.Input > input:focus::placeholder,
.Number > input:focus::placeholder,
.ColorPicker input:focus::placeholder {
  opacity: 0;
}


a:has(.card){
  text-decoration: none;
  display: grid;

  width: fill-available;
  max-width: min(100%, 37em);
  /* suggested min-width:20em; */
}
.card:hover{
  cursor: pointer;
}

/* ----what each shares------ */
.card{

}
.card:hover{

}
a:has(.card){

}
.card img{

}
.card .title{

}
.card .text{

}
.card{

}
.card.hasImage{

}

.card.Primary{

}
.card.Primary .title,
.card.Primary .text{

}


/* ------if each element is not in card----- */
.card:not(.hasImage){

}
.card:not(.hasImage) > .title{

}
.card:not(.hasImage) > .description{

}

.card:not(.hasImage) > img{
  display: none;
}
.card:not(.hasTitle) > .title{
  display: none;
}
.card:not(.hasText) > .description{
  display: none;
}

/* --------all-------- */
.card.hasImage.hasTitle.hasText{

}
.card.hasImage.hasTitle.hasText  img{

}
.card.hasImage.hasTitle.hasText  .title{
}
/* -------only image and text----------- */

.card.hasImage.hasText:not(.hasTitle){

}
/* ------only title and text----- */
.card.hasTitle.hasText:not(.hasImage){

}
.card.hasTitle.hasText:not(.hasImage) .title{

}
.card.hasTitle.hasText:not(.hasImage) .text{

}
/* ------only text----------- */
.card.hasText:not(.hasImage.hasTitle){

}
.card.hasText:not(.hasImage):not(.hasTitle) .text{

}
/* ---------------------------- */

@media (orientation: landscape){
  .card{

  }
}



.Layout6{
  width: 90%;
  max-width: 580px;
  margin: 1em auto;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.Layout6>
.profile{
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
.Layout6
.photo{
  margin:0 auto;
  width: fit-content;
}
.Layout6
.name{
  font-size: 1.5em;
  font-weight: bold;
}
.Layout6
.bio span{
  display: block;
}

.Layout6
.social-section{
  height: 3em;
}

.Layout6>
.link-section{
  display: flex;
  gap: 1em;
}
.Layout6>
.link-section > *{

}

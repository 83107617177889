.AssetGrid{
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  margin: 0 1em;
}

@media (orientation:landscape) {
  .AssetGrid{
    grid-template-columns: 1fr 1fr 1fr;
  }
}

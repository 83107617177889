.Layout5{
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 40vh 1px 1fr auto;
  grid-gap: .5em;

  max-width: 100%;

  overflow: none;
}

.Layout5>.profile{
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  font-size: 1.5em;
  overflow: hidden;
  filter: drop-shadow(0 0 9px var(--primary));
}
.Layout5>.profile::before{
  --bannerWidth: 2em;
  content: "";
  position: absolute;
  left:50%;
  bottom:50%;
  max-width: calc(200vw - var(--bannerWidth));
  width: 100%;
  aspect-ratio:1/1;
  border-style: solid;
  border-radius: 3em;
  border-color: var(--tertiary);
  border-width: var(--bannerWidth) var(--bannerWidth);
  transform:translate(-50%, 0) rotate(45deg);
}
.Layout5>.profile>.image{
  position: relative;
  height: 95%;
  max-width: 100%;
  background-size: cover;
  aspect-ratio:1/1;

  margin:auto;
/*  clip-path: polygon(27% 5%, 73% 5%, 100% 50%, 73% 95%, 27% 95%, 0% 50%);*/
  clip-path: polygon(5% 27%, 5% 73%, 50% 100%, 95% 73%, 95% 27%, 50% 0%);
}
.Layout5>.profile>.image::before{
  content:"";
  position: absolute;
  inset:0em;
  background: var(--quarnary);
  --point1:50% 3%;
  --point2:7.5% 28.5%;
  --point3:7.5% 71.5%;
  --point4:50% 97%;
  --point5:92.5% 71.5%;
  --point6:92.5% 28.5%;
  clip-path: polygon(
    0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%,
    var(--point1), var(--point2), var(--point3),var(--point4),var(--point5),var(--point6),var(--point1)
  );
}
.Layout5>.profile>.actions{
  position: absolute;
  width: 90%;
  inset:5% 5% auto 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Layout5>.profile>.actions>div{
  color:var(--primary);
  cursor: pointer;
  background: var(--tertiary);
  text-align: center;
  border-radius: .25em;
  padding: .1em .25em;
  filter: drop-shadow(1px 1px 20px black);
}
.Layout5 hr{
  margin: auto;
  width: 90%;
  height: 0px;
  border-color: var(--tertiary);
}
.Layout5
.info{
  text-align: center;
  margin-bottom: 1em;
}
.Layout5
.name{
  font-size: 4.5em;
}
.Layout5
.bio{
  font-size: 1.5em;
}

.Layout5>.social-section,
.Layout5>.links{
  margin: 0 auto 1em;
  width:90%;
}

.Layout5>
.social-section{
  margin: .5em auto;
  height: 4.5em;
  width: 100%;
  max-width: calc(95vw - 1em);

  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.Layout5>
.links{
  display: flex;
  flex-direction: column;
}
.Layout5>
.links>
.link-section{
  gap: 0.5em;
  align-items: center;
}


@media only screen and (min-width: 768px) {
  .Layout5{
    grid-template-rows: 1fr;
    grid-template-columns: 5fr 1px 1fr 5fr;
    max-width: 98%;
    height: 100%;
    margin: auto auto auto 0;
  }
  .Layout5>.profile::before{
    left:50%;
    top:50%;
    width: auto;
    height: 100%;
    transform:translate(-110%, -50%) rotate(45deg);
  }
  .Layout5>.profile>.image{
    height: auto;
    max-height: 100%;
    width: 100%;
  }
  .Layout5>.profile>.actions{
    width: auto;
    height: 90%;
    inset: 5% 5% 5% auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .Layout5 hr{
    width: 0px;
    height: 90%;
    border-color: var(--tertiary);
  }
  .Layout5>
  .links{
    margin: auto;
    width: 100%;
  }
  .Layout5>.links>.info{
    text-align: left;
  }
  .Layout5>
  .social-section{
    flex-direction: column;
    width: 4em;
    height: 80dvh;
    margin: auto 0;
  }

  .banner::after{
    transform:translate(-125%, -50%) rotate(45deg);
    max-height: calc(200vw - var(--bannerWidth));
  }

  .Layout5>
  .links{
    max-height: 85vh;
  }
  .Layout5>
  .links>
  .link-section{
    mask-image: linear-gradient(to bottom, transparent 0%, black calc(1em), black calc(100% - 5em), transparent 100%);
    padding-bottom: 4em;
    align-items:flex-start;
  }
}

.Footer{
  width: 100%;
  align-self: flex-end;
  align-items: center;

  color: var(  --tertiary);
  opacity: .5;
  font-size: 1.5em;
  font-weight: bolder;

  margin: 1.5em 0 0.5em;
}

.Footer > *{
  color:inherit;
  text-decoration: none;
}

.Footer > .FooterSocials{
  height: 2em;
  display:flex;
  flex-direction: row;
  justify-content: center;
  margin: .5em 0;
  display: none;
}
.Footer > .FooterSocials > *{
  max-height: 2em;
}


.Footer > .FooterLinks {
  display:flex;
  flex-direction: row;
  justify-content: center;
  font-family: sans-serif;
  font-weight: normal;
  font-size: .75em;
  margin: .2em 0;
  border-collapse: collapse;
}
.Footer > .FooterLinks > a {
  padding: 0em 1.5em;
  color: var(--tertiary);
  border-left:1px solid var(--tertiary);
  border-right:1px solid var(--tertiary);
  cursor: pointer;

  margin-top: .25em;
}
.Footer > .FooterLinks > a:first-child {
  border-left: 0px;
}
.Footer > .FooterLinks > a:last-child {
  border-right: 0px;
}

.Pricing{

  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  color: #e8f3ffff;
/*  background: radial-gradient(ellipse at top, #17181F 0%, #41144D 180%);*/
}


.PricingCard{
  border-radius: 1em;
  overflow: hidden;

  font-size: 1rem;
  width: fit-content;
}
.PricingCard > .header{
  background: #05355cff;
  color: #bcc2c8ff;
  display: flex;
  flex-direction: row;

  font-size: 1.3em;
}
.PricingCard > .header > .title{
  display: block;
  text-align: left;
  margin: 0 2em 0 1em;
  width: fit-content;

  font-size: 1em;
  font-weight: bold;
}
.PricingCard > .header > .price{
  display: block;
  text-align: right;
  margin: 0 1em 0 2em;
  width: fit-content;
  color: #259ef9ff;

  font-size: 1em;
}

.PricingCard > .list{
  display: block;
  color: #202327ff;
  background: #bcc2c8ff;
  padding: .5em 2em;
  font-size: 1em;

}
.PricingCard > .list > li{
  width: fit-content;
}

.Apps{
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(385px,1fr));
  margin-top: 2em;
}

.AppGrid{
  display: grid;
  grid-template-columns: 7em 5fr;
  grid-template-rows: 3em 3em;
  grid-template-areas:
    'icon name'
    'icon description';
  text-align: left;
}

@media (min-width: 768px) {
  .Pricing{
    width: 80%;
    padding: 0 10%;
  }
}

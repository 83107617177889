.ContactIcon{
  position: relative;
  display: block;
  width: 8em;
  height: 8em;
  cursor: pointer;
}
.ContactIcon > a{
  text-decoration: none;
}
.icon{
  aspect-ratio:1;
  border-radius: 50%;
  height: 100%;
  overflow: hidden;
}
.icon > img{
  width: 100%;
  height: 100%;
  object-fit:cover;
}
.plus{
  position: absolute;
  right: -.25em;
  top: 50%;
  transform:translate(0,-50%);
  font-size: 3em;
  font-weight: bold;
  display: flex;
}
.contactText{
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: .25em .5em;
  border-radius: .5em;
  background: var(--primary);
  white-space: nowrap;
  font-size: .8em;
}

.Blog{


}

.BlogArticle{

}
.Blog h1 {
  text-transform:capitalize;
}
.Blog .Button{
  background: var(--tertiary);
  color:var(--primary);
  padding: .25em .5em;
  border-radius: .25em;
  text-decoration: none;
  cursor: pointer;
}


.Blog .BlogArticle{
  text-decoration: none;
  cursor: pointer;
  color: var(--tertiary);
  display: grid;
  grid-template-areas: 'img header'
                      'img preview';
  grid-template-columns: auto 1fr;
  column-gap:1em;
}
.Blog .BlogArticle img{
  grid-area: img;
  height: auto;
  max-width: 3em;
  margin: auto auto 0 auto;
}
.Blog .BlogArticle h3{
  margin-bottom: 0;
  grid-area: header;
}
.Blog .BlogArticle > div{
  grid-area: preview;

}

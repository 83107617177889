/* ----what each shares------ */
a:has(.fade){
  filter: drop-shadow(2px 2px 10px #11111199);
  min-width: min(100%, 23em);
}
a:has(.fade):hover{
  filter: drop-shadow(2px 2px 8px var(--secondary));
}
.fade{
  position: relative;
  background: var(--primary);
  display: flex;
  flex-direction: column;
  text-align: right;
  padding: 1em 2em 1em 4em;
  border-radius: 1em;
  overflow: hidden;
}
.fade img{
  position: absolute;
  top:0;
  left:0;
  bottom:0;
  height: 100%;
  mask-image: linear-gradient(90deg, #ffffffff, #00000000);
}
.fade .title{
  font-size: 2.5em;
  font-weight: bolder;
  -webkit-text-stroke: 2px var(--tertiary);
  color: #00000000;
  text-transform: uppercase;
  letter-spacing: .1em;
  z-index: 1;
}
.fade .text{
  font-size: 1.25em;
  color: var(--tertiary);
  z-index: 1;
}
.fade{

}
.fade.hasImage{

}

.fade.Primary{
  background: var(--quarnary);
}
.fade.Primary .title{
  -webkit-text-stroke: 2px var(--primary);
}
.fade.Primary .text{
  color: var(--primary);
}


/* ------if each element is not in card----- */
.fade:not(.hasImage){

}
.fade:not(.hasImage) > .title{

}
.fade:not(.hasImage) > .description{

}


.fade:not(.hasImage) > .title{
  margin-right:var(--content-margin-right);
}

/* --------all-------- */
.fade.hasImage.hasTitle.hasText{

}
.fade.hasImage.hasTitle.hasText  img{

}
.fade.hasImage.hasTitle.hasText  .title{
}
/* -------only image and text----------- */

.fade.hasImage.hasText:not(.hasTitle){

}
/* ------only title and text----- */
.fade.hasTitle.hasText:not(.hasImage){

}
.fade.hasTitle.hasText:not(.hasImage) .title{

}
/* ------only text----------- */
.fade.hasText:not(.hasImage.hasTitle){

}
.fade.hasText:not(.hasImage):not(.hasTitle) .text{

}
/* ---------------------------- */

@media (orientation: landscape){
  .fade{

  }
}

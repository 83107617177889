
a:has(.basic){
  text-decoration: none;
  display: grid;
  align-items: end;
}

/* ----what each shares------ */
.basic{
  position: relative;
  background: none;
  width: auto;
  display: grid;
  text-align: left;
  font-size: .75rem;
  font-weight: bold;
  grid-template:  "img title" auto
                  "img text" auto / auto 1fr;

  padding: 0;
  align-items: space-between;
  color: var(--tertiary);
  height: 100%;


  background: var(--primary);
  border-radius: 1em;
  filter: drop-shadow(2px 2px calc(.5em - 2px) #11111199);
  transition: filter .25s;
}
.basic:hover{
  filter: drop-shadow(2px 2px 8px var(--secondary));
}
a:has(.basic){

}
.basic img{
  grid-area: img;
  align-self: end;
  max-height: 6em;
  padding: 1em;
  margin: 0 0 auto auto;
  filter: drop-shadow(var(--secondary) 0 0 10px);
}
.basic .title{
  grid-area: title;
  font-size: 3em;
  padding: 1rem 0 0 1rem;
  margin: auto auto 0 0;
}
.basic .text{
  grid-area: text;
  font-size: 1.5em;
  padding: 0 0 1rem 1rem;
  width: calc(100% - var(--content-margin-right));
}
.basic{
  min-height: calc(100% - 2.75em);
}
.basic.hasImage{

}

.basic.Primary{
  background: var(--quarnary);
}
.basic.Primary .title,
.basic.Primary .text{
  color: var(--primary);
}


/* ------if each element is not in card----- */
.basic:not(.hasImage){

}
.basic:not(.hasImage) > .title{

}
.basic:not(.hasImage) > .description{

}

/* --------all-------- */
.basic.hasImage.hasTitle.hasText{

}
.basic.hasImage.hasTitle.hasText  img{

}
.basic.hasImage.hasTitle.hasText  .title{
}
/* -------only image and text----------- */

.basic.hasImage.hasText:not(.hasTitle){

}
/* ------only title and text----- */
.basic.hasTitle.hasText:not(.hasImage){

}
.basic.hasTitle.hasText:not(.hasImage) .title{
  margin-right: 1rem;
}
.basic.hasTitle.hasText:not(.hasImage) .text{
  margin-right: 1rem;
}
/* ------only text----------- */
.basic.hasText:not(.hasImage.hasTitle){

}
.basic.hasText:not(.hasImage):not(.hasTitle) .text{
  padding: 1rem;
}
/* ------only img----------- */
.basic.hasImage:not(.hasText):not(.hasTitle){
  grid-template:'title img text';
  grid-template-columns: 0 1fr 0;
}
.basic.hasImage:not(.hasText):not(.hasTitle) img{
  margin: auto;
  padding: 0.25em;
  max-height: 8em;
}
/* ---------------------------- */


@media (orientation: landscape){
  .basic{
    font-size: 1rem;
  }
}

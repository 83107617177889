
.AffiliateLink{
	background: var(--secondary20);
/*	width: fit-content;*/
	/* max-width: 15em; */
	padding: .5em .75em;
	border-radius: .5em;
	box-shadow: 1px 1px 3px var(--tertiary);

	text-decoration: none;
	color: var(--tertiary);

	position: relative;
	display: grid;
	grid-template-areas:'img title code'
						'img description description';

	transition: background-color 0.5s;

}
.AffiliateLink:hover{
	background: var(--secondary10);
}
.AffiliateLink::before{
	content:' ';
	position: absolute;
	inset: 0;
	box-shadow: 1px 1px 10px var(--tertiary);
	opacity: 0;
	transition: opacity 0.5s;
	border-radius: inherit;
}
.AffiliateLink:hover::before{
	box-shadow: 1px 1px 10px var(--tertiary);
	opacity: 1;
}
.AffiliateImage{
	grid-area: img;
}
.AffiliateTitle{
	grid-area: title;
	font-size: 1.25rem;
	white-space: nowrap;
	align-self: flex-end;
	font-weight: bold;
	text-align: left;
}
.AffiliateCode{
	grid-area: code;
	font-size: 0.9rem;
	white-space: nowrap;
	align-self: flex-end;
	margin-left: 1.25em;
	position: relative;
}
.AffiliateCode:hover > span{
	text-decoration: underline;
}
.AffiliateCode .CopiedConfirmation{
	position:absolute;
	pointer-events: none;
	top:-2em;
	background:var(--primary);
	padding: .25em;
	opacity:0;
}
.AffiliateCode .CopiedConfirmation.Visible{
	animation: ConfirmationFadeOut 2s;
}
@keyframes ConfirmationFadeOut {
	0% {opacity: 1;}
  50% {opacity: 1;}
  100% {opacity: 0;}
}
.AffiliateDescription{
	grid-area: description;
	font-size: 1rem;
	text-align: left;
	opacity: .75;
	text-wrap: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: max-content;
	max-width: 15em;
}

.AffiliateLink > a {

}

.Loader{
  --loader_color: #3489c7;

  min-width: 80px;
  height: 100%;
  aspect-ratio:1/1;
  perspective: 200px;
  position: relative;
}


/* The dot */
.Loader .dot {
  position: absolute;
  width: 60%;
  height: 60%;
  margin: 10% 0 0 30%;
  background: radial-gradient(#0000 24%, #000f 100%),
              radial-gradient(#0000 24%, var(--loader_color) 25%);
  border-radius: 50%;
  background-color: transparent;
  animation: dot 500ms cubic-bezier(.49,.06,.43,.85) infinite alternate;
}

.Loader .dot:nth-child(2) {
  width: 80%;
  height: 80%;
  margin: 0% 0 0 20%;
  background: radial-gradient(#0000 34%, #000f 100%),
              radial-gradient(#0000 34%, var(--loader_color) 35%);
  filter: brightness(1.2);
  animation-delay: -850ms;
}

.Loader .dot:nth-child(3) {
  width: 100%;
  height: 100%;
  margin: -10% 0 0 10%;
  background: radial-gradient(#0000 45%, #000f 100%),
              radial-gradient(#0000 44%, var(--loader_color) 45%);
  filter: brightness(1.4);
  animation-delay: -700ms;
}

@keyframes dot {
  0% {
    filter: hue-rotate(0deg);
    transform: rotateX(24deg) rotateY(20deg) rotateZ(0deg) translateZ(-25px);
  }

  100% {
    filter: hue-rotate(45deg);
    transform: rotateX(20deg) rotateY(20deg) rotateZ(50deg) translateZ(0px);
  }
}

.Directory{
  width: 80%;
  margin: 0 auto;
}
.Directory h2{
	margin-top: 1.5em;
	text-align: left;
}

.Directory .Links{
	gap: 10px;
	justify-items: flex-start;
	margin: -1em -2em;
}
.Directory .Links::-webkit-scrollbar{
  display: unset !important;
}
.Directory .Links::-webkit-scrollbar-corner{
	background: #0000;
}
.Directory .Links.old{
	display:grid;
	grid-gap:5px;
	grid-template-columns:auto;

}

.Directory .Input{
	margin-left:auto;
}

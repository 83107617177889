.Analytics{
  width: calc(100% - 1em);
  min-height: calc(100% - 2em);
  padding: .5em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(1fr, 384px));
  grid-gap: 1em;
  grid-auto-flow:dense;
}


.dashCard{
  background: #29292e;
  color: #cecedb;
}

.span1, .span2, .span3, .span4{
  grid-column: span 1;
}
@media screen and (min-width: calc(512px + 50px) ){
  .span2, .span3, .span4{
    grid-column: span 2;
  }
}
@media screen and (min-width: calc(768px + 50px) ){
  .span3, .span4{
    grid-column: span 3;
  }
}
@media screen and (min-width: calc(1024px + 50px) ){
  .span4{
    grid-column: span 4;
  }
}

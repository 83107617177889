.Parallax{
  width: 100%;
  height: 100%;
  min-height: min(80vh, 800px);
  overflow-x: hidden;
}


.movable{
  position: absolute;
  /* transition: left .1s linear; */
}

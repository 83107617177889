@font-face {
  font-family: "Gill Sans Medium";
  src: url('../../public/fonts/GillSansMedium.otf');

}
.content:has(.Layout3){
  display: flex;
  align-items: center;
}
.padder:has(.Layout3){
  align-content: center;
}

.Layout3{
  --silver: linear-gradient(#D9D9D9, #fff 40%, #555);
  margin: auto;
  padding: 1.15em;
  --borderColor: var(--quarnary);
  background: var(--borderColor) repeating-linear-gradient(-30deg, #BEC0BF55 0%, #0000 15%, #BEC0BF55 30%);
  box-shadow: 10px 10px 5px 5px #00000077;
  height: fit-content;
  border-radius: 1.25em;
  overflow: hidden;
  aspect-ratio: 5/7;
  max-width: 90%;
  max-height: 80vh;

  font-family: "Gill Sans Medium";
  text-shadow: 0 0 2px white;

  --normal: url('https://pokecardmaker.net/assets/icons/types/scarletAndViolet/colorless.png');
  --fire: url('https://pokecardmaker.net/assets/icons/types/scarletAndViolet/fire.png');
  --water: url('https://pokecardmaker.net/assets/icons/types/scarletAndViolet/water.png');
  --grass: url('https://pokecardmaker.net/assets/icons/types/scarletAndViolet/grass.png');
  --lightning: url('https://pokecardmaker.net/assets/icons/types/scarletAndViolet/lightning.png');
}
.Layout3.fullArt{
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}
.Layout3 .cardContent{
  background: url('https://media.gettyimages.com/id/472711481/video/heat-haze.jpg?s=640x640&k=20&c=ER39Ge18VG-ef6xRYfDN7YmnHVgATtjLvPAf6QdpmBk='),
  var(--primary) linear-gradient(60deg, var(--secondary) 0%, var(--primary) 100%);
  background-blend-mode: lighten;
  background-size: cover;
  border-radius: .75em;
  overflow: hidden;
  margin:0;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: visible;
  box-shadow: 0px 0px 3px 3px #ffffff44;
  color: black;

  position: relative;
  display: flex;
  flex-direction: column;
}

.Layout3
.cardTitle{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
/*.Layout3
.basic{
  --sizing: .9em;
  background: var(--silver);
  border-radius: calc(var(--sizing) * 1) 0 0 calc(var(--sizing) * .5);
  font-style: italic;
  font-weight: bold;
  color:#333;
  text-shadow: 0 0 2px grey;
  position: absolute;
  left:-2%;
  top:0%;
  z-index: 2;
  font-size: var(--sizing);
  padding: calc(var(--sizing) * .1) calc(var(--sizing) * .6);
}*/
.Layout3
.basic{
  --sizing: .9em;
  background: url('../../public/pokeBanner2.svg');
  background-size: contain;
  background-repeat: round;
  border-radius: calc(var(--sizing) * 1) 0 0 calc(var(--sizing) * .5);
  height: 1rem;
  font-style: italic;
  font-weight: bold;
  color:#333;
  text-shadow: 0 0 2px grey;
  position: absolute;
  left:-2%;
  top:0%;
  z-index: 2;
  font-size: var(--sizing);
  padding: calc(var(--sizing) * .1) calc(var(--sizing) * 1.5) calc(var(--sizing) * .1) calc(var(--sizing) * .8);
}
.Layout3
.name{
  font-size: 1.75rem;
  font-weight: bold;
  margin-left:1.2em;
  margin-left: 2.2em;
}
.Layout3
.hp{
  font-size: 1.75rem;
  font-weight: bold;
  width: fit-content;
  display:flex;
  flex-direction: row;
  align-items: end;
  margin-top: .1em;
}
.Layout3
.hp
img{
  content: var(--lightning);
  height: 1em;
  aspect-ratio: 1/1;
  display: inline;
  margin: auto 0 auto .2em;
  object-position: center;
}
/*.Layout3
.description{
  font-size: 0.95em;
  border-radius: 53px 20px;
  background: var(--silver);
  width: 75%;
  padding: 0.2em 2em 0.2em 1em;
  margin: 0 auto;
  transform: translate(0, -0.5em);
  position: relative;
  overflow: visible;
}
.Layout3
.description:after{
  content: '';
  position: absolute;
  inset: -0.2em -0.5em;
  background: url('../../public/pokeBanner.svg#path1');
  background-size: 130% 80%;
  background-repeat: no-repeat;
}*/
.Layout3
.description{
  font-size: 0.75em;
  width: 75%;
  padding: 0.2em 2em 0.2em 2em;
  margin: 0 auto;
  transform: translate(0, -0.5em);
  position: relative;
  overflow: visible;
  z-index: 1;
}
.Layout3
.description:before{
  content: '';
  background: url('../../public/pokeBanner3.svg#path1');
  background-size: contain;
  background-repeat: round;
  position: absolute;
  inset: -0.05em -2em -0.2em -2em;
  z-index: -1;
}

.imageContainer{
  position:relative;
  margin:0 1em 0 1em;
  width: calc(100% - 2em);
  aspect-ratio:16/9;
  padding: .2em;
  display:block;
  background: var(--silver);
  border-radius: .2em;
}
.profileImg{
  object-fit:cover;
  aspect-ratio:16/9;
  width: 100%;
  height:100%;
}


.Layout3
.bio > span{
  display: block;
}





.Layout3
.social-section{
  width: 60%;
  height: 33%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
}

.Layout3
.link-section{
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  max-height: 50%;
  justify-content: space-around;
  margin: .5em 0 0 0
}
.Layout3
.link-section > *{
  margin-top:.5em;
}



.Layout3 .footerBorder{
  width: 90%;
  height: 4px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Layout3 .footerBorder:before{
  content: " ";
  display: block;
  width: 70%;
  height: 100%;
  background: var(--silver);
  border-radius: 3px 1px;
}
.Layout3 .footerBorder:after{
  content: " ";
  display: block;
  width: 28%;
  height: 100%;
  background: var(--silver);
  border-radius: 3px 1px;
}
.Layout3 .cardFooter{
  margin: 0;
  height:3em;
}

.Layout3 .Contact{
  border-radius: 1.1em;
  cursor:pointer;
  text-align:right;
  margin: .2rem 1em 0 auto;
  padding: 0rem .8rem .2rem .8rem;
  font-weight:bold;
  width: fit-content;
  background: var(--silver);

  position: relative;
  float: right;
  width: 23%;
  font-size: .8em;
  right: 0;
  bottom: 0;
}

.clipLeft{
  --width: 50;
  --height: 20px;
/*  clip-path: path('M 0 0 v 20 h 80 c 10,0 0,-50 10,-50 s 10,0 10,-50 z');*/
/*  clip-path: path('M 0 0 c 0,20,20,50,50,50 z');*/
/*  clip-path: path('M0,0 v 20 h 20 a 10,10 0 0 1 10,-10 a 10,10 0 0 0 10,-10 z');*/
  clip-path: url(#clipLeft);
}
.clipRight{

}
.clipBoth{

}


.MoveCard{
  font-family: Font Awesome 6 Brand;
  width: 90%;
  margin: auto;
  color: black;
  font-weight:bolder;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  grid-template-columns: fit-content(1em) 1fr;
}
a:has(.MoveCard){
  text-decoration: none;
  display: grid;
  align-items: end;
}
.MoveCard:hover{
  cursor: pointer;
}
.MoveCard img{
  display: inline-block;
  height: 1.7em;
  position: absolute;
  left: 0;
}
.MoveCard .title{
  margin: auto;
  max-width: calc(100% - 4em);
  font-size: 1.5em;
  text-align: left;
}
.MoveCard .text{
 margin-left: 0.2em;
 width: calc(100% - 0.2em);
 text-align: left;
 grid-column: span 2;
}
.MoveCard.hasImage{

}
.MoveCard:not(.hasImage)>img{
  content: var(--normal);
}

.AddCodesFree{
	height: 100%;
	display: flex;
	flex-direction: column;
}

.AddCodesFree form{
  width:90%;
}
.AddCodesFree input,
.AddCodesFree .Input,
.AddCodesFree .Toggle{
  width:100%;
}

@media (orientation: landscape) {
  .AddCodesFree form{
    width:40%;
  }
}

.Layout4{
  width: 95%;
  height: calc(100dvh - 5em);
  margin: auto;

  display: grid;
  grid-template-rows: auto 1fr auto auto auto;
  grid-gap: .5em;
}

.ClipContent{
  position: absolute;
  overflow: hidden;
  inset: 0;
}
.MainContent{
  position: absolute;
  inset: 0 0 0 0;
}
.MainContent > .Loader{
  position: absolute;
  top:40%;
  left:50%;
  height:30dvh;
  transform:translate(-50%, -50%);
}

.Layout4
.info{
  --margin: .5em;

  text-align: left;
  width: calc(100% - calc(2*var(--margin)) );
  margin: auto var(--margin);
  position: relative;
  text-shadow: 0 0 5px var(--secondary);
  overflow: hidden;
}
.Layout4
.name{
  color: var(--quarnary);
  font-size: 4.5em;
  font-weight: bold;
}
.Layout4
.bio{
  font-size: clamp(1px, 2em, 4vw);
}


.Layout4>
.social-section{
  height: 4.5em;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  z-index: 1;
}
.Layout4
.link-section{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 1em;
  min-width: 100%;
}
.Layout4>
.link-section::-webkit-scrollbar {
  display: none;
}

.Layout4>
.link-section > *{
  width: 80%;
}


@media only screen and (min-width: 768px) {
  .MainContent{
    inset: 0 -30vw 0 0;
  }
  .Layout4{
    grid-template-rows: auto 11fr auto auto;
  }

  .Layout4
  .info{
    --margin: 3em;

    font-size: 1.5rem;
    pointer-events: none;
  }
  .Layout4
  .bio{
    width: 50%;
  }
  .Layout4>
  .social-section{
    position: absolute;
    pointer-events: none;
    flex-direction: column;
    right: 1em;
    width: 4em;
    height: 80dvh;
    z-index: 2;
  }
}

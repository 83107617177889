.FrankenCard{
  display: grid;
  grid-template-rows: 2em repeat(9, auto);
  grid-template-columns: 5em repeat(2, 1fr);
  grid-auto-flow: column;
  grid-gap: 1em;
  overflow: scroll;
  width:100%;
  align-items: center;
}
.FrankenCard > *{
  /* border: 3px solid hsl(from var(--quarnary) h s l/1); */
}

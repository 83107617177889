/* ----what each shares------ */
a:has(.block){
  --pixel-size: .25em;
  transition: filter .25s;
  filter: drop-shadow(2px 2px 10px #11111199);
  min-width: 20em;
}
a:has(.block):hover{
  filter: drop-shadow(2px 2px 8px var(--secondary));
}
@font-face {
  font-family: "Block";
  src: url('../../public/fonts/Jersey10.ttf');
}
@font-face {
  font-family: "PixelifySans";
  src: url('../../public/fonts/PixelifySans-VariableFont_wght.ttf');
}
.block{

  margin: 0;
  padding: 0;
  /* max-height: calc(4em * floor( 100% / 4em)); */
  min-height: calc(15 * var(--pixel-size));
  max-height: calc(30 * var(--pixel-size));

  /* background-image: url('../../public/displacement.jpg'),
              linear-gradient(hsl(from var(--tertiary) h s l / .5), hsl(from var(--tertiary) h s l / .5)),
              repeating-linear-gradient(transparent 0em, transparent var(--pixel-size), #ffffff11 var(--pixel-size), #ffffff11 calc(2*var(--pixel-size))),
              repeating-linear-gradient(90deg, transparent 0em, transparent var(--pixel-size), #ffffff11 var(--pixel-size), #ffffff11 calc(2*var(--pixel-size))); */
  background-color: var(--primary);
  background-image: linear-gradient(hsl( from var(--primary) h s l / .7), hsl( from var(--primary) h s l / .7)),
                    url('../../public/noise.png');
  color: var(--tertiary);
  background-size: calc(1000 * var(--pixel-size));
  /* filter: blur(5px); */
  image-rendering: pixelated;
  /* background-blend-mode:multiply; */

  display: grid;
  grid-template: 'img title none' 1fr
                  'img text none' 1fr;
  grid-template-columns: 1fr 8fr 1fr;
  align-content: center;
  align-items: center;

  text-shadow: 1px 3px 3px var(--primary);
  /* filter: drop-shadow(2px 2px 8px var(--secondary)); */

  overflow-y: visible;
  clip-path: polygon( calc(100% - 5*var(--pixel-size)) calc(0*var(--pixel-size)),
                      calc(100% - 5*var(--pixel-size)) calc(1*var(--pixel-size)),
                      calc(100% - 3*var(--pixel-size)) calc(1*var(--pixel-size)),
                      calc(100% - 3*var(--pixel-size)) calc(2*var(--pixel-size)),
                      calc(100% - 2*var(--pixel-size)) calc(2*var(--pixel-size)),
                      calc(100% - 2*var(--pixel-size)) calc(3*var(--pixel-size)),
                      calc(100% - 1*var(--pixel-size)) calc(3*var(--pixel-size)),
                      calc(100% - 1*var(--pixel-size)) calc(5*var(--pixel-size)),
                      calc(100% - 0*var(--pixel-size)) calc(5*var(--pixel-size)),

                      calc(100% - 0*var(--pixel-size)) calc(100% - 5*var(--pixel-size)),
                      calc(100% - 1*var(--pixel-size)) calc(100% - 5*var(--pixel-size)),
                      calc(100% - 1*var(--pixel-size)) calc(100% - 3*var(--pixel-size)),
                      calc(100% - 2*var(--pixel-size)) calc(100% - 3*var(--pixel-size)),
                      calc(100% - 2*var(--pixel-size)) calc(100% - 2*var(--pixel-size)),
                      calc(100% - 3*var(--pixel-size)) calc(100% - 2*var(--pixel-size)),
                      calc(100% - 3*var(--pixel-size)) calc(100% - 1*var(--pixel-size)),
                      calc(100% - 5*var(--pixel-size)) calc(100% - 1*var(--pixel-size)),
                      calc(100% - 5*var(--pixel-size)) calc(100% - 0*var(--pixel-size)),

                      calc(5*var(--pixel-size)) calc(100% - 0*var(--pixel-size)),
                      calc(5*var(--pixel-size)) calc(100% - 1*var(--pixel-size)),
                      calc(3*var(--pixel-size)) calc(100% - 1*var(--pixel-size)),
                      calc(3*var(--pixel-size)) calc(100% - 2*var(--pixel-size)),
                      calc(2*var(--pixel-size)) calc(100% - 2*var(--pixel-size)),
                      calc(2*var(--pixel-size)) calc(100% - 3*var(--pixel-size)),
                      calc(1*var(--pixel-size)) calc(100% - 3*var(--pixel-size)),
                      calc(1*var(--pixel-size)) calc(100% - 5*var(--pixel-size)),
                      calc(0*var(--pixel-size)) calc(100% - 5*var(--pixel-size)),

                      calc(0*var(--pixel-size)) calc(5*var(--pixel-size)),
                      calc(1*var(--pixel-size)) calc(5*var(--pixel-size)),
                      calc(1*var(--pixel-size)) calc(3*var(--pixel-size)),
                      calc(2*var(--pixel-size)) calc(3*var(--pixel-size)),
                      calc(2*var(--pixel-size)) calc(2*var(--pixel-size)),
                      calc(3*var(--pixel-size)) calc(2*var(--pixel-size)),
                      calc(3*var(--pixel-size)) calc(1*var(--pixel-size)),
                      calc(5*var(--pixel-size)) calc(1*var(--pixel-size)),
                      calc(5*var(--pixel-size)) calc(0*var(--pixel-size))
                    );
}
a:has(.block){

}
.block img{
  max-height: 100%;
  grid-area: img;
}
.block .title{
  font-family: "PixelifySans";
  font-size: 3rem;
  font-weight:bolder;
  grid-area: title;
}
.HorizontalList .block .title{
  white-space: nowrap;
}
.VerticalList .block .title{
}
.block .text{
  font-family: "PixelifySans";
  font-size: 1.5rem;
  grid-area: text;
}
.block{

}
.block.hasImage{

}

.block.Primary{
  background-image: linear-gradient(hsl( from var(--quarnary) h s l / .7), hsl( from var(--quarnary) h s l / .7)),
                    url('../../public/noise.png');
  color: var(--primary);
  text-shadow: 1px 3px 3px var(--quarnary);
}
.block.Primary .title,
.block.Primary .text{

}


/* ------if each element is not in card----- */
.block:not(.hasImage){

}
.block:not(.hasText){
  grid-template: 'img title none'
                  'img title none';
  grid-template-columns: 1fr 8fr 1fr;
}
.block:not(.hasTitle){
  grid-template: 'img text none'
                  'img text none';
  grid-template-columns: 1fr 8fr 1fr;
}
.block:not(.hasImage) > .title{

}
.block:not(.hasImage) > .description{

}

.block:not(.hasImage) > img{
  height: 0;
}
.block:not(.hasTitle) > .title{
  height: 0;
}
.block:not(.hasText) > .description{
  height: 0;
}
.block:not(.hasText) > .title{
  white-space: normal;
}

/* --------all-------- */
.block.hasImage.hasTitle.hasText{

}
.block.hasImage.hasTitle.hasText  img{

}
.block.hasImage.hasTitle.hasText  .title{
}
/* -------only image and text----------- */

.block.hasImage.hasText:not(.hasTitle){

}
/* ------only title and text----- */
.block.hasTitle.hasText:not(.hasImage){

}
.block.hasTitle.hasText:not(.hasImage) .title{

}
/* ------only text----------- */
.block.hasText:not(.hasImage.hasTitle){

}
.block.hasText:not(.hasImage):not(.hasTitle) .text{

}
/* ------only image----------- */
.block.hasImage:not(.hasText):not(.hasTitle){
  grid-template: 'title img text'
                  'title img text';
  grid-template-columns: 1fr 8fr 1fr;
}
.block.hasImage:not(.hasText):not(.hasTitle) img{
  margin: auto;
}
/* ---------------------------- */

@media (orientation: landscape){
  .block{

  }
}

.Template{
  /* TODO: remove after "content" layout refactor */
  min-height: 100dvh;
  display: flex;
  flex-direction: column;

  width: 100%;
  overflow-x: hidden;
  position: relative;

}
.Template > *:not(.Header, .Footer){
  flex-grow: 1;
}

.ListView{
  color: #deffff;
  font-size: 1.5rem;
  grid-area: content;
  min-width: 100px;
}
.ListView > a{
  color: inherit;
}

.scroll-wrapper{
  overflow-x: scroll;

}

.table{
  display: table;
  border-radius: .25em;
  background: #05355cff;
  overflow: hidden;
  border-collapse: collapse;
  margin: 1em auto;
  min-width: 90%;
  color: inherit;
}

.tableFeatures{
  display: table-header-group;
  background: #02233bff;
}

.record{
  display: table-row;
  border-top: 1px solid black;
  transition: .2s background-color;
}
.record:hover{
  background:#03122e;
}

.cell{
  display: table-cell;
  min-width: 1.5em;
  max-width: 20em;
  padding: .1em .5em;
  overflow-x: hidden;
  text-align: left;
  /* white-space: nowrap; */
}

.cell > *{
  /* align-items: center; */
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
}

/* Overall scroller container */
.image-scroller {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-height: 60vh;
}

/* Main image container with navigation */
.main-image-container {
  position: relative;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.main-image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  margin: auto;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
}

.nav-button.prev {
  left: 10px;
}

.nav-button.next {
  right: 10px;
}

.nav-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Thumbnail container styles */
.thumbnail-container {
  display: flex;
  gap: 10px;
  padding:15px;
  overflow-x: scroll;
  /* width: 100%; */
  max-width: calc(100% - 30px);
}
.thumbnail-container::-webkit-scrollbar  {
  width: 0;
}

.thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0.6;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.thumbnail:hover {
  transform: scale(1.1);
  opacity: 1;
}

.active-thumbnail {
  transform: scale(1.2);
  opacity: 1;
  border: 2px solid #007bff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

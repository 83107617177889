

.Hero2 .main{
  display:grid;
  grid-template-areas:  "content"
                        "img"
                        "info"
                        "mocks";
  margin: 3em 2em auto 2em;
  filter: drop-shadow(2px 2px 8px #222);
  text-align: center;
}
.Hero2 .main h1{
  margin: 0;
}

.Hero2 .content{
  grid-area: content;
  margin-top: 2em;
}
.Hero2 .img{
  grid-area: img;
  margin: 6em auto;
}
.Hero2 .info{
  grid-area: info;
  display: flex;
  flex-direction: column;
  margin: 12em 20% 4em 20%;
  gap: 2em;
}
.Hero2 .info img{
  display: block;
  margin: auto;
  max-height: 4em;
  margin-bottom: 1em;
}
.Hero2 .mocks{
  grid-area: mocks;
  position: relative;
}
.Hero2 .mocks img{
  border-radius: 1em;
  max-height:30em;
  max-width: 90%;
  height: auto;
  margin: auto 5%;
  position: relative;
  outline: .5em solid black;
}
.Hero2 .mocks div>div{
  font-size:3rem;
  position: absolute;
  top:40%;
  z-index: 2;
  cursor: pointer;
}
.Hero2 .mocks div:first-child{
  left:0;
}
.Hero2 .mocks div:last-child{
  right:0;
}
.Hero2 .mocks>div::after{
  content:"";
  background: black;
  z-index: 2;
  height:.8em;;
  width: 3em;
  position: absolute;
  display: block;
  top:1em;
  left:50%;
  transform:translate(-50%, -50%);
  border-radius: .4em;
}

.login{
  position: absolute;
  top:1em;
  right:1em;
  font-size: 1rem;
}
.login > a{
  color: inherit;
  text-decoration: none;
}


.Hero2 input{
  width: 30em !important;
}


.login, .callToAction{
  width: fit-content;
  block-size: fit-content;
  background: #0570c0ff;
  border-radius: .5em;
  margin: 0 auto;
  padding: .4em .7em;
  font-weight:bolder;
  display: inline;
}

.callToAction{
  cursor: pointer;
  transition: filter .25s linear;
}
.callToAction:hover{
  filter: drop-shadow(2px 2px 8px var(--secondary));
}

@media (orientation: landscape) {
  .Hero2{



    width: 80%;
    height: calc(100vh - 3em);
    margin: auto 0 0 0;
    padding: 0 10%;
    display: flex;
    flex-direction: column;

    font-size: 1.3em;

    overflow: hidden;
    position: relative;
    z-index: 0;
  }

  .Hero2 .main{
    margin: 7em auto auto auto;
    grid-template-areas:  "img img content content"
                          "info info info mocks";
    text-align: left;
  }
  .Hero2 .img{
    margin: auto;
  }
  .Hero2 .info{
    flex-direction: row;
    margin: 7em 1em auto 1em;
    font-size: .8em;
  }
}



.star{
  --xCoor: 40;
  --yCoor: 40;
  --delay: 0;

  position: absolute;
  top:calc(var(--xCoor) * 96% + 2%);
  left:calc(var(--yCoor) * 96% + 2%);

  border-radius: 50%;

  width: 4px;
  height: 15px;
  background: #ffffffaa;

  animation: shimmer 4s linear calc(var(--delay) * 4s) infinite;

  opacity: 0;
}
.star::before{
  display: block;
  content: " ";
  height: 4px;
  width: 15px;


  border-radius: 50%;

  background: #ffffffaa;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes shimmer {
  0% {transform: rotate(0deg) scale(.8); opacity: 0;}
  25% {opacity: 0;}
  50% {transform: rotate(180deg) scale(.5); opacity: 1;}
  75% {opacity: 0;}
  100% {transform: rotate(360deg) scale(.8); opacity: 0;}
}

.FrankenSocial{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: .5em;
  height:100%;
  width:100%;
  align-items: center;
}

.FrankenSocial > .icon-holder{
  width: 5em;
  height: fit-content;
  margin: auto;
  text-align: left;

  display:grid;
  grid-template-rows: auto auto;
  grid-gap: .25em;
}
.FrankenSocial > .icon-holder > div{
  width: 5em;
}

.App:has(.Product){
  overflow-y: scroll;
  overflow-x:hidden;
}

.Product{

  display: grid;
  grid-template-columns: 1fr;
  width: 80%;
  flex-grow: 1;
  margin: 0 auto;
}
.ProductInfo{
  max-width: 100%;
  min-width: 0;
}
.Product .main-image{
  max-height:40vh;
}
.ProductInfo > *{
  margin: 1em 0;
  text-align: left;
}
.PaymentBar{
  margin: 2em 0;
}
.PaymentBar .Input,
.PaymentBar .Input > input{
  width: 100%;
}


@media only screen and (min-width: 768px) {

  .Product{
    width: 95%;
    grid-template-columns: calc(70% - 2em) 30%;
    grid-gap: 2em;
  }
  .ProductInfo{

  }
  .PaymentBar{

  }

}

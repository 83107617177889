.ManagementHeader{
  display: grid;
  grid-template-columns: 1fr;
/*  grid-template-rows: auto 1fr;*/
  grid-template-rows: 1fr;
 /* grid-template-areas:
    "header header"
    "menu content";*/
  grid-template-areas:
    "content";
  height: 100vh;
  width: 100vw;
  background: linear-gradient(-45deg, #141417ff, #4a4f59ff);
  color: #deffff;

  overflow: hidden;
}
.menu{
  grid-area: menu;
  background-color: #03080844;
  backdrop-filter: blur(10px);
  overflow: hidden;
  flex-direction: column;
  padding: 0 1em;
  align-items: left;
  position: absolute;
  display:none;
  height: 100%;
  width: 0px;
  transition: width .5s;
  z-index: 2;
}
.hamburger{
  position: absolute;
  top:0;
  right:0;
  height: 2em;
  width: 2em;
  cursor: pointer;
}
.hamburger::before{
  content:"☰";
  position: absolute;
  top:0;
  right:.25em;
  font-size: 2em;
}
.hamburger > input{
  opacity: 0;
}
.hamburger:has(input:checked) + .menu{
  display:flex;
  width: 10px;
  min-width: fit-content;
}
.hamburger:has(input:checked)::before{
  content:"X";
}

@media only screen and (min-width: 480px) {
  .ManagementHeader{
    grid-template-areas:
      "menu content";
    grid-template-columns: 200px 1fr;
  }
  .menu{
    display: flex;
    position: relative;
    width: fit-content;
  }
  .hamburger{
    display: none;
  }

}
@media only screen and (min-width: 768px) and (max-width:1079px) {
  
}
@media only screen and (min-width: 1080px) {
  
}

.header{
/*  grid-area: header;*/
  padding: .25em 0;
  font-size: 1.5em;
  font-weight: bolder;
  color: #0783e4ff;
}
.header > img{
  display: inline;
  position: relative;
  height: 1em;
  transform: translate(0, .17em); ;
}


.menu > a{
  text-decoration: none;
  color: inherit;
  font-weight: bold;
  font-size: 1.25em;
  margin: .25em;
}
.menu > a:hover{
  filter: drop-shadow(2px 2px 8px var(--secondary));
}

.menu > *{
  width: fit-content;
}

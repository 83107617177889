.Toast {
  --message-color: #33eeaa;
  --icon-content: "✔";

  border-radius: 1em;
  background: var(--message-color);
  width: fit-content;
  position: relative;
  font-family: sans-serif;
  display: flex;
  flex-direction: row;

  color: #ffffffee;
/*  animation: fade 10s linear forwards;*/
}
.Toast.Success{
  --message-color: #29e332;
  --icon-content: "✔";
}
.Toast.Warning{
  --message-color:#e39229;
  --icon-content: "!";
}
.Toast.Error{
  --message-color:#e32f29;
  --icon-content: "X";
}
.Toast.Notification{
  --message-color:#292fe3;
  --icon-content: "*";
}

.Toast .content {
  padding: .75em 1em;
  position: relative;
  text-align: left;
}

.Toast .message {
  font-size: 2em;
  text-align: left;
}

.Toast .icon {
  position: relative;
  z-index: 2;
  background: inherit;
  overflow: visible;
}

.Toast .icon::before {
  content: var(--icon-content);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 3em;
  height: 3em;
  background: inherit;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: inherit;
}

.Toast .icon::after {
  content: var(--icon-content);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 3em;
  height: 3em;
  background: inherit;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #00000064;
  mix-blend-mode: multiply;
}

.Toast .close {
  position: absolute;
  top: .5em;
  right: 1em;
  cursor: pointer;
}


.Toast .animation-container {
  border-radius: 1em 0 0 1em;
  overflow: hidden;
  width: 4em;
  position: relative;
}

.Toast .animation {
  position: absolute;
  inset: -10%;
  --hue-rotate: 310deg;

  filter: blur(3px) contrast(1000) invert(1) opacity(.4);
  mix-blend-mode: multiply;
  background: #000f;
}

.Toast .bubble {
  --start-x: 120%;
  --start-y: 120%;
  --size: 20px;
  --duration: 5s;
  --offset: -5s;

  position: absolute;
  transform: translate(-50%, 50%);
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  background: white;
  left: var(--start-x);
  bottom: var(--start-y);
}

.Toast .bubble:nth-child(n+3) {
  animation: float var(--duration) linear infinite var(--offset);
}

@keyframes float {
  0% {
    left: 0%;
    bottom: 0%;
  }

  100% {
    left: var(--start-x);
    bottom: var(--start-y);
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
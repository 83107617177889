.VideoAsset{
}

.VideoAsset .Preview{
  display: flex;
  min-width: 100px;
  min-height: 100px;
}
.VideoAsset .Render{
  display:flex;
  background: green;
  min-width: 100px;
  min-height: 100px;
}
.VideoAsset .Preview > *,
.VideoAsset .Render > *{
  margin: auto;
}

.SocialButton{
  aspect-ratio:1/1;
  transition: box-shadow .25s;
  pointer-events: auto;
}

.SocialButton:hover{
  cursor: pointer;
}
.SocialButton > a{
  display: block;
  width: 100%;
  height: 100%;
}
.SocialButton svg{
  aspect-ratio: 1/1;
  max-width: 100%;
  max-height: 100%;
}

/* 'minimal','frame','inverted','outline','border','holo','twisted_metal','circle','glass','bevel' */
.minimal svg{
  fill: var(--social_color);
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.8));
}

.frame{
  border-radius: 20%;
  background: linear-gradient(45deg, var(--primary), var(--secondary));
}
.frame svg{
  fill: var(--social_color);
  margin: 12.5%;
}

.inverted{
  border-radius: 20%;
  background: linear-gradient(45deg, var(--primary) -100%, var(--social_color) 50%, var(--tertiary) 200%);
  /* background: var(--social_color); */
}
.inverted svg{
  fill: var(--secondary);
  margin: 12.5%;
}

.outline svg{
  fill: none;
  stroke: var(--social_color);
  stroke-width: 3px
}

.border{
  border-radius: 20%;
  border: 2px solid var(--social_color);
}
.border svg{
  fill: none;
  stroke: var(--social_color);
  stroke-width: 3px;
  margin: 12.5%;
}


.holo{
  --holo_alpha_color: hsl(from var(--social_color) h s l / .2);
  --holo_alpha_color_2: hsl(from var(--social_color) h s l / .5);

  border-radius: 20%;
  border: 2px solid var(--social_color);
  box-shadow: inset 0px 0px 7px 7px var(--holo_alpha_color);
  background: repeating-linear-gradient(-5deg, #0000 0%, var(--holo_alpha_color) 6%, #0000 12%);
  background-size: 100% 200%;
  animation: holoScreen 10s linear infinite;
  background-blend-mode: multiply;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.8), 0 0 0 rgba(0, 0, 0, 0.8);
}
.holo svg{

  fill: var(--holo_alpha_color_2);
  stroke: var(--social_color);
  stroke-width: 3px;
  margin: 12.5%;
  background-blend-mode:darken;
}
@keyframes holoScreen {
   from {
     background-position: top;
   }
   to {
     background-position: bottom;
   }
}

/* TODO: Needs work - probably need to wait for color var refactor */
.twisted_metal{
}
.twisted_metal svg{
  fill: none;
  stroke: url(#twisted-metal);
  stroke-width: 5px;
}

.circle{
  border-radius: 50%;
  background: linear-gradient(45deg, var(--primary), var(--secondary));
}
.circle svg{
  fill: var(--social_color);
  margin: 17.5%;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.8));
}


.glass{
  border-radius: 20%;
  background: #9992;
  backdrop-filter: blur(5px);
}
.glass svg{
  fill: var(--social_color);
  margin: 12.5%;
}


.bevel svg{
  fill: var(--social_color);
  /* filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.8)); */
  filter:url(#bevel-filter);
  transition: filter .25s;
  /* box-shadow: inset 3px 4px 15px rgba(255, 255, 255, 0.9), inset -3px -4px 14.9px rgba(0, 0, 0, 0.9); */
}
.bevel svg:hover{
  filter: url(#bevel-filter) drop-shadow(0px 0px 4px var(--secondary));
}

.SocialButton.frame,
.SocialButton.inverted,
.SocialButton.circle,
.SocialButton.glass{
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.8);
  transition: box-shadow .25s;
}
.SocialButton.frame:hover,
.SocialButton.inverted:hover,
.SocialButton.border:hover,
.SocialButton.holo:hover,
.SocialButton.circle:hover,
.SocialButton.glass:hover{
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.8), 1px 1px 10px var(--secondary);
}
.SocialButton.minimal svg,
.SocialButton.twisted_metal svg{
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.8));
  transition: filter .25s;
}

.SocialButton.minimal svg:hover,
.SocialButton.outline svg:hover,
.SocialButton.border svg:hover,
.SocialButton.twisted_metal svg:hover{
  filter: drop-shadow(0px 0px 6px var(--secondary));
}


.phone{
  --xRotate:0;
  --yRotate:-10;
  --zRotate:-20;

  --width: 250px;
  --height: 400px;
  --phoneColor: radial-gradient(ellipse, #555 50%, #111 100%);

  width:var(--width);
  height:var(--height);
  position:relative;
  perspective:1000px;
  transform-style:
  preserve-3d;
  transform: rotateX(calc(var(--xRotate) * 1deg)) rotateY(calc(var(--yRotate) * 1deg)) rotateZ(calc(var(--zRotate) * 1deg));
  background:var(--phoneColor);
  border-radius:30px;
}
@media (hover:none) {
  .phone{
    transition: transform .1s linear;
  }
}
.phone::after{
  content: " ";
  display: block;
  width:var(--width);
  height:var(--height);
  position:relative;
  background-color:var(--phoneColor);
  background-color:#111;
  transform: translate3d(0,0,-15px);
  border-radius:30px;
}

.screen{
  position:absolute;
  inset:7px;
  border-radius:30px;
  background-color:#fff;
  transform-style:preserve-3d;
  transform:translateZ(1px);
  box-shadow:inset 0 0 10px #000;
  overflow: hidden;
}
.screen:after{
  content:"";
  position:absolute;
  height:10px;
  width:40px;
  border-radius:5px;
  background:#000;
  top:15px;
  left:50%;
  transform:translate(-50%,0);
}

/* h1{
  font-size:24px;
  text-align:center;
  margin:50px auto;
} */
